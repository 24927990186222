<template>
  <div>
    <Button
      type="link"
      class="fw-normal p-0"
      :disabled="!hasPurchasePaymentTermManage"
      @click.stop="openPaymentDueModal"
    >
      {{ $t('payment.paymentTable.notDefined') }}
    </Button>
    <PaymentDueModal
      v-if="isPaymentDueModalOpen"
      :loading="paymentTermLoading || paymentTermChangedForceLoading"
      :supplier="supplier.name"
      :country-code="currentTenant.countryCode"
      @close="isPaymentDueModalOpen = false"
      @submit="handlePaymentDueNetChange"
    />
  </div>
</template>

<script>
import { reject, omit, isNil } from 'ramda';
import { ref, computed, onBeforeUnmount, getCurrentInstance } from 'vue';

import { Button } from '@/modules/core';
import { PaymentDueModal, useCreateTerm, useUpdateTerm } from '@/modules/term';
import { useTenancy } from '@/modules/auth';
import { useGlobalPermissions } from '@/modules/permissions';

const timeoutIds = [];
const sleep = async (ms) => new Promise((resolve) => timeoutIds.push(setTimeout(resolve, ms)));

export default {
  components: {
    Button,
    PaymentDueModal,
  },
  props: {
    businessId: { type: String, required: true },
    supplier: { type: Object, required: true },
    paymentTerm: { type: Object, default: null },
  },
  setup(props, { emit }) {
    const { $message, $i18n } = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const isPaymentDueModalOpen = ref(false);

    const { hasPurchasePaymentTermManage } = useGlobalPermissions();

    const { createTerm, loading: createTermLoading, onError: createTermError } = useCreateTerm();
    const termUpdated = () => {
      $message.success($i18n.t('commons.messages.action.success'));
      isPaymentDueModalOpen.value = false;
      emit('term-saved');
    };

    createTermError(() => {
      $message.error($i18n.t('commons.messages.action.error'));
    });

    const { updateTerm, loading: updateTermLoading, onError: updateTermOnError } = useUpdateTerm();

    updateTermOnError(() => {
      $message.error($i18n.t('commons.messages.action.error'));
    });

    onBeforeUnmount(() => timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId)));

    return {
      hasPurchasePaymentTermManage,
      emit,
      currentTenant,
      createTerm,
      updateTerm,
      termUpdated,
      isPaymentDueModalOpen,
      paymentTermLoading: computed(() => createTermLoading.value || updateTermLoading.value),
      paymentTermChangedForceLoading: ref(false),
    };
  },
  methods: {
    openPaymentDueModal() {
      this.isPaymentDueModalOpen = true;
    },
    async handlePaymentDueNetChange(newPaymentDueNet, eom) {
      this.paymentTermChangedForceLoading = true;
      if (!this.paymentTerm) {
        await this.createTerm({
          termCreateInput: {
            businessId: this.businessId,
            supplierId: this.supplier.id,
            fromDate: new Date(),
            type: 'payment',
            paymentDueNet: newPaymentDueNet,
            eom,
          },
        });
      } else {
        await this.updateTerm({
          termId: this.paymentTerm.id,
          termUpdateInput: reject(isNil, {
            businessId: this.businessId,
            ...omit(['id', 'active', '__typename'], this.paymentTerm),
            paymentDueNet: newPaymentDueNet,
            eom,
          }),
        });
      }
      await sleep(8000);
      this.termUpdated();
      this.paymentTermChangedForceLoading = false;
    },
  },
};
</script>
