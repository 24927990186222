<template>
  <div>
    <Button
      v-if="billedAmounts.length"
      type="link"
      class="text-typography-primary p-0"
      @click.stop="setPaymentInvoicesModalPayment"
    >
      <template v-if="billingsLeftToBePaid.length !== billedAmounts.length">
        <p>
          {{
            $t('operationManagement.ongoingOperations.table.billingCountField', {
              total: billedAmounts.length,
              leftToPay: billingsLeftToBePaid.length,
            })
          }}
        </p>
      </template>
      <template v-else>
        <p>{{ billedAmounts.length }}</p>
      </template>
    </Button>
    <template v-else>
      <p>{{ $t('operationManagement.ongoingOperations.table.notYetReceived') }}</p>
    </template>
    <PaymentInvoicesModal
      v-if="paymentInvoicesModalPayment"
      :payment="paymentInvoicesModalPayment"
      @close="paymentInvoicesModalPayment = null"
    />
  </div>
</template>

<script>
import { ref } from 'vue';

import { Button } from '@/modules/core';

export default {
  components: {
    Button,
    PaymentInvoicesModal: () => import('@/modules/payment/customer/components/PaymentInvoicesModal'),
  },
  props: {
    billedAmounts: { type: Array, default: () => [] },
    billingsLeftToBePaid: { type: Array, default: () => [] },
    billingIds: { type: Array, default: () => [] },
    supplier: { type: Object, required: true },
    customer: { type: Object, required: true },
    totalBilledAmount: { type: Number, default: null },
    totalPaidAmount: { type: Number, default: null },
  },
  setup() {
    return {
      paymentInvoicesModalPayment: ref(null),
    };
  },
  methods: {
    setPaymentInvoicesModalPayment() {
      this.paymentInvoicesModalPayment = {
        billingIds: this.billingIds,
        supplier: this.supplier,
        customer: this.customer,
        totalPayment: this.billedAmounts.length
          ? { totalBilledAmount: this.totalBilledAmount, totalPaidAmount: this.totalPaidAmount }
          : null,
      };
    },
  },
};
</script>
