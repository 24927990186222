<template>
  <PaymentDueDateButton
    v-if="businessId && (!paymentTerm || (paymentTerm && !Number.isInteger(paymentTerm.paymentDueNet)))"
    :supplier="supplier"
    :payment-term="paymentTerm"
    :business-id="businessId"
    @term-saved="() => $emit('term-saved')"
  />
  <el-popover
    v-else
    v-model="paymentDueDateVisible"
    v-capture-scroll="onScroll"
    placement="bottom"
    trigger="click"
    popper-class="text-typography-primary p-2"
  >
    <Button
      slot="reference"
      type="link"
      class="p-0"
      :class="`text-${PAYMENT_STATUS_COLORS[paymentStatus]} ${PAYMENT_STATUS_COLORS[paymentStatus]}`"
    >
      {{ dueDate }}
    </Button>
    <div class="pb-1 ps-1">
      <div v-if="paymentStatus !== PAYMENT_STATUS.UNPAID" class="d-flex gap-1">
        <AlertIcon :class="`text-${PAYMENT_STATUS_COLORS[paymentStatus]}`" />
        <div class="pt-1">
          <p class="fw-bold">{{ $t(`payment.paymentTable.paymentStatus.${paymentStatus}`) }}</p>
          <small>
            <i18n :path="`payment.paymentTable.overduePopover.${paymentStatus}`">
              <template #date>
                <small v-if="paymentStatus === PAYMENT_STATUS.OVERDUE" class="fw-bold">
                  {{ getPastDaysNumber(paymentDueDate) }}
                </small>
                <small v-if="paymentStatus === PAYMENT_STATUS.APPROACHING_DUE" class="fw-bold">
                  {{ getApproachingDaysNumber(paymentDueDate) }}
                </small>
              </template>
            </i18n>
          </small>
        </div>
      </div>
      <hr v-if="paymentStatus !== PAYMENT_STATUS.UNPAID" class="my-2" />
      <div class="d-flex gap-1">
        <TermsAndAgreementsIcon />
        <div class="pt-1">
          <p class="fw-bold">{{ $t('payment.paymentTable.paymentTerms') }}</p>
          <small>
            {{
              $tc(
                `payment.paymentTable.paymentTermsPopover.paymentDueNet${paymentTerm.eom ? 'Eom' : ''}`,
                paymentTerm.paymentDueNet,
                {
                  count: paymentTerm.paymentDueNet,
                }
              )
            }}
          </small>
        </div>
      </div>
    </div>
  </el-popover>
</template>

<script>
import { DateTime } from 'luxon';
import { ref } from 'vue';

import { Button } from '@/modules/core';
import { TermsAndAgreementsIcon, AlertIcon } from '@/assets/icons';
import { PAYMENT_STATUS, PAYMENT_STATUS_COLORS, PAYMENT_METHOD_TYPE } from '@/modules/payment/types';
import { captureScroll } from '@/directives/capture-scroll';

import PaymentDueDateButton from './PaymentDueDateButton';

export default {
  components: {
    Button,
    TermsAndAgreementsIcon,
    AlertIcon,
    PaymentDueDateButton,
  },
  directives: {
    captureScroll,
  },
  props: {
    paymentDueDate: { type: String, default: null },
    paymentTerm: { type: Object, default: null },
    paymentStatus: { type: String, default: null },
    supplier: { type: Object, required: true },
    onTermSave: { type: Function, default: null },
    businessId: { type: String, required: true },
  },
  setup() {
    return {
      paymentDueDateVisible: ref(false),
      PAYMENT_STATUS,
      PAYMENT_STATUS_COLORS,
      PAYMENT_METHOD_TYPE,
    };
  },
  computed: {
    dueDate() {
      return this.paymentDueDate
        ? new Date(this.paymentDueDate).toLocaleDateString(this.$i18n.locale, {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            timeZone: 'UTC',
          })
        : '-';
    },
  },
  methods: {
    onScroll() {
      this.paymentDueDateVisible = false;
    },
    getPastDaysNumber(paymentDueDate) {
      const { days, months, years } = DateTime.local()
        .diff(DateTime.fromISO(paymentDueDate).toUTC(), ['days', 'months', 'years'])
        .toObject();
      if (years) return this.$tc('payment.paymentTable.overduePopover.years', years, { count: Math.round(years) });
      if (months) return this.$tc('payment.paymentTable.overduePopover.months', months, { count: Math.round(months) });
      if (days) return this.$tc('payment.paymentTable.overduePopover.days', days, { count: Math.round(days) });
    },
    getApproachingDaysNumber(paymentDueDate) {
      const { days } = DateTime.local().diff(DateTime.fromISO(paymentDueDate).toUTC(), ['days']).toObject();
      return this.$tc('payment.paymentTable.overduePopover.days', days, { count: Math.round(days) });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

button.text-warning.warning:not(:hover) {
  color: change-color($color: $warning, $lightness: 38.1%) !important;
}
button.text-danger.danger:not(:hover) {
  color: change-color($color: $error, $lightness: 40%) !important;
}
</style>
