<template>
  <el-dialog
    v-loading="loading"
    top="40vh"
    width="400px"
    visible
    append-to-body
    custom-class="rounded balance-alignment-modal p-4"
    :show-close="false"
    @close="$emit('close')"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center pb-4">
        <h3>{{ translate('title') }}</h3>
        <Button type="icon" class="p-0" @click="$emit('close')">
          <CloseIcon width="20" height="20" />
        </Button>
      </div>
    </template>
    <div class="mb-6">
      <div class="mb-4">
        <p class="text-typography-primary">
          {{ translate('content', { date: formatDate(reconciliation.periodEnd) }) }}
          <b>{{ formatMoney(amount) }}</b>
        </p>
      </div>
      <div>
        <h4 class="text-typography-primary mb-2">{{ translate('notes') }}</h4>
        <el-input v-model="note" />
      </div>
    </div>
    <template #footer>
      <Button type="secondary" @click="$emit('close')">{{ $t('commons.cancel') }}</Button>
      <Button @click="submit">{{ translate('validate') }}</Button>
    </template>
  </el-dialog>
</template>

<script>
import { ref, computed } from 'vue';

import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';
import { usePayables } from '@/modules/payment';
import { useCurrency } from '@/modules/core/compositions/money-currency';

export default {
  components: { Button, CloseIcon },
  props: {
    reconciliation: { type: Object, required: true },
  },
  setup(props) {
    const options = computed(() => ({ enabled: !!props.reconciliation.businessId }));
    const { payables, loading } = usePayables(
      computed(() => ({
        businessId: props.reconciliation.businessId,
        supplierId: props.reconciliation.supplierId,
        toDate: props.reconciliation.periodEnd,
      })),
      options
    );
    const { formatToCurrency } = useCurrency({ maximumFractionDigits: 2 });

    return {
      formatToCurrency,
      loading,
      note: ref(props.reconciliation.balanceAlignment.note),
      amount: computed(() => payables.value[0]?.balance ?? 0),
    };
  },
  methods: {
    translate(key, obj) {
      return this.$t(`reconciliation.balanceAlignmentModal.${key}`, obj);
    },
    formatMoney(value) {
      return this.formatToCurrency(value) ?? '';
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      });
    },
    submit() {
      return this.$emit('validate', { reconciliationId: this.reconciliation.id, amount: this.amount, note: this.note });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .balance-alignment-modal {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
  }
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
}
</style>
